import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Package/actions";
import { getList as getCategoryList } from "../../Redux/Category/actions";

function List({
  useAuth,
  getList,
  list,
  loginData,
  onDelete,
  getCategoryList,
  categoryList,
}) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
    if (!categoryList.data) {
      getCategoryList(tokenData);
    }
    if (categoryList.data) {
      setFilterOptions(
        categoryList?.data?.data
          .filter((e) => e.type?.category_key === "destination")
          .map((e) => {
            return { value: e.id, text: e.title };
          })
      );
    }
  }, [list.data, categoryList.data]);

  const tableConfig = {
    title: "Packages",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Title", selector: "package_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "package_title",
    updatePath: "/package/update",
    addPath: "/package/add",
    noDataMessage: "No Package exists",
    updateLinkColumn: "package_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.packages,
    categoryList: state.category,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    getCategoryList: (tokenData) => dispatch(getCategoryList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

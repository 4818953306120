import React, {useEffect } from "react";
import { Form, Input, Col, Row, Switch, Select, Alert, Spin } from "antd";
import DashboardActionBar from "../DashboardActionBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;
const CategoryTypeForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading

  const loadEntityById = async() => {
    await getEntityById(tokenData, params.id) 
  }
  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById()
    }
  }, []);

  useEffect(() => {
    const alert = document.querySelector('.custom-alert')
      setTimeout(() => {
        alert.classList.add('fadeout')
      }, 5000)
  
  }, [selectedForm.error])

  const selectOptionsData = [
    "destination",
    "blog",
    "articles",
    "accommodation",
    "transportation",
    "trip_gradings",
    "trip_style",
    "meals",
    "faqs",
    "trip_info",
    "team"
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      type: data.dataById?.data.category_type,
      key: data.dataById?.data.category_key,
      description: data.dataById?.data.description,
      status: data.dataById?.data.status,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Category Type",
    titleToUpdate: "Update Category Type",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Category Type",
    taskCompleteAddMessage: "Category Type is added!",
    taskCompleteUpdateMessage: "Category Type is updated!",
    discardLink: "category-types",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />
        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
        <div className="common-module mt-3">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Title"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  { min: 3, max: 255, message: "Invalid title" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Category Key"
                name="key"
                rules={[
                  {
                    required: true,
                    message: "key is required",
                  },
                ]}
              >
                <Select>
                  {selectOptionsData?.map((itm) => {
                    return <Option key={itm}>{itm}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        </Spin>
      </Form>
    </>
  );
};

export default CategoryTypeForm;

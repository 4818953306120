import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Switch,
  TreeSelect,
  Spin,
  Alert,
  Upload,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";
import { BASE_URL } from "../../Redux/urls";

import { getList as categoryList } from "../../Redux/Category/actions";
import { getList as categoryTypeList } from "../../Redux/CategoryType/actions";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;
const { TreeNode } = TreeSelect;

const CategoryForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getCategoryList,
  categoryListData,
  getCategoryTypeList,
  categoryTypeListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [description, setDescription] = useState(
    isUpdateForm && data.dataById?.data.description
  );
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.featured &&
          data.dataById.data.featured.full_path
      : null
  );

  const [bannerImage, setBannerImage] = useState();
  const [formHidden, setFormHidden] = useState();
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading =
    selectedForm.loading || categoryListData.loading || data.loading;

  const loadCategory = async () => {
    await getCategoryList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!categoryListData.data) {
      loadCategory();
    }
    if (!categoryTypeListData.data) {
      getCategoryTypeList(tokenData);
    }
  }, [categoryListData.data, categoryTypeListData.data]);
  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const typeIdData = categoryTypeListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.category_type,
    };
  });

  const categoryListOptions = categoryListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.title,
      ...(itm.children?.length !== 0 && {
        children: itm.children?.map((a) => {
          return {
            value: a.id,
            label: a.title,
            ...(a.children?.length !== 0 && {
              children: a.children?.map((b) => {
                return {
                  value: b.id,
                  label: b.title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const find = (array, id) => {
    var result;
    array.some((o) => (result = o.id === id ? o : find(o.children || [], id)));
    return result;
  };

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById?.data.title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      parent_id: data.dataById?.data.parent_id,
      type_id: data.dataById?.data.type_id,
      status: data.dataById?.data.status == 1 ? true : false,
      is_featured: data.dataById?.data.is_featured == 1 ? true : false,
      description: data.dataById?.data.description,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.thumb_path,
      featured_alt: data.dataById?.data.featured?.alt_text,
      featured_caption: data.dataById?.data.featured?.caption,
      banner_alt: data.dataById?.data.banner?.alt_text,
      banner_caption: data.dataById?.data.banner?.caption,
      banner_image: data.dataById?.data.banner?.full_path,
    });
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData
              ).then(() => {
                loadCategory();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              description,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData
          ).then(() => {
            loadCategory();
          });
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                true,
                history
              ).then(() => {
                loadCategory();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            true,
            history
          ).then(() => {
            loadCategory();
          });
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById();
                loadCategory();
              });
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
            loadCategory();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
    setFeaturedImage("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Category",
    titleToUpdate: "Update Category",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Category",
    taskCompleteAddMessage: "Category is added!",
    taskCompleteUpdateMessage: "Category is updated!",
    discardLink: "categories",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />
        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Category title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Category title" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({ slug: slugify(e.target.value) })
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Parent Categories"
                  name="parent_id"
                  rules={[
                    {
                      required: true,
                      message: "Category is required",
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll={true}
                    onSelect={async (e) => {
                      if (e !== 0) {
                        const selectedObj = find(
                          categoryListData.data?.data,
                          e
                        );
                        setFormHidden(true);
                        setFieldsValue({ type_id: await selectedObj.type_id });
                      } else {
                        setFieldsValue({ type_id: null });
                        setFormHidden(false);
                      }
                    }}
                  >
                    <TreeNode value={0} title="No parent" />
                    {categoryListOptions?.map((parent) => {
                      const { value, label, children } = parent;
                      return (
                        <TreeNode key={value} value={value} title={label}>
                          {children?.length !== "0" &&
                            children?.map((child) => {
                              const { value, label, children } = child;
                              return (
                                <TreeNode
                                  key={value}
                                  value={value}
                                  title={label}
                                >
                                  {children?.length !== "0" &&
                                    children?.map((gChild) => {
                                      const { value, label } = gChild;
                                      return (
                                        <TreeNode
                                          key={value}
                                          value={value}
                                          title={label}
                                        />
                                      );
                                    })}
                                </TreeNode>
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                  </TreeSelect>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                xl={8}
                hidden={formHidden}
              >
                <Form.Item
                  label="Category type"
                  name="type_id"
                  rules={[
                    {
                      required: true,
                      message: "Category Type is required",
                    },
                  ]}
                >
                  <Select>
                    {typeIdData?.map((itm) => {
                      return (
                        <Option value={itm.value} key={itm.value}>
                          {itm.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Featured?"
                    valuePropName="checked"
                    name="is_featured"
                  >
                    <Switch
                      checkedChildren="Featured"
                      unCheckedChildren="Not Featured"
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={16}>
                <Form.Item label="Description" name="description">
                  <TextEditor
                    value={description}
                    initialValue={isUpdateForm && description}
                    onEditorChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8}>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      className="mb-0"
                      label="Meta Description"
                      name="meta_description"
                    >
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={10}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Featured Image"
                        image={
                          featured_image ||
                          (isUpdateForm &&
                            data.dataById?.data.featured?.full_path)
                        }
                        height={process.env.REACT_APP_CATEGORY_THUMBINAL_HEIGHT}
                        width={process.env.REACT_APP_CATEGORY_THUMBINAL_WIDTH}
                        setImage={(image_data) => {
                          setFeaturedImage(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {featured_image && image_crop_open && (
                        <ImageCrop
                          height={
                            process.env.REACT_APP_CATEGORY_THUMBINAL_HEIGHT
                          }
                          width={process.env.REACT_APP_CATEGORY_THUMBINAL_WIDTH}
                          image={featured_image}
                          setCroppedImage={setFeaturedImage}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="featured_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="featured_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row " xs={24} xl={14}>
                <div className="common-module bg-white">
                  <h6>Banner Image</h6>
                  <Row gutter={10}>
                    <Col xs={24}>
                      <Form.Item
                        name="bannerImage"
                        style={{ marginBottom: "0" }}
                      >
                        <Upload
                          listType="picture-card"
                          maxCount={1}
                          name="tet"
                          beforeUpload={(file) => {
                            return false;
                          }}
                          onChange={(file) => setBannerImage(file)}
                        >
                          {isUpdateForm &&
                          data.dataById?.data.banner?.full_path &&
                          !bannerImage ? (
                            <img
                              src={
                                BASE_URL + data.dataById?.data.banner?.full_path
                              }
                            />
                          ) : (
                            <UploadOutlined style={{ fontSize: "27px" }} />
                          )}
                        </Upload>
                        <span className="text-muted">
                          Size should be 1920x750
                        </span>
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={8}>
                      <Form.Item label="Banner Alt" name="banner_alt">
                        <Input placeholder="Alt Text" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={16}>
                      <Form.Item label="Banner Caption" name="banner_caption">
                        <Input placeholder="banner caption" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* <Col className="gutter-row banner-image" xs={24} xl={14}>
                <Form.List name="banners" className="bg-white">
                  {(fields, { add, remove }) => (
                    <>
                      <h6>Banner Images</h6>
                      {fields.map((field, index) => (
                        <div
                          key={field.key}
                          className="card bg-white"
                          align="baseline"
                        >
                          <div class="card-body">
                            <label className="form-label">
                              Banner #{index + 1}{" "}
                              <span
                                class="btn-delate"
                                onClick={() => remove(field.name)}
                              >
                                Delete
                              </span>
                            </label>

                            <Row>
                              <Col xs={24} lg={6}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "banner_image"]}
                                >
                                  <div class="input-group">
                                    <input type="file" />
                                  </div>
                                </Form.Item>
                              </Col>

                              <Col xs={24} lg={6}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "banner_alt"]}
                                >
                                  <Input placeholder="Alt Text" />
                                </Form.Item>
                              </Col>

                              <Col xs={24} lg={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "banner_caption"]}
                                >
                                  <Input placeholder="banner caption" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add New Banner
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col> */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryListData: state.category,
    categoryTypeListData: state.categoryType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: (tokenData) => dispatch(categoryList(tokenData)),
    getCategoryTypeList: (tokenData) => dispatch(categoryTypeList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);


import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Tripinfo/actions";
import clean from 'lodash-clean'

function List({ useAuth, getList, list, onDelete }) {
  const dataList = clean(list)
  const tableConfig = {
    title: "Trip Info",
    filterOptionTitle: "Category Type",
    //filterOptions: filter_options,
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'page_title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type_id',
    searchByColumn: 'page_title',   
    addPath: "/tripinfo/add",
    updatePath: "/tripinfo/update",
    noDataMessage: "No trip info exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'page_title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={dataList}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.tripinfo,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
